import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getUser, getUserCreditHistory, dashboardUserVideos } from '../../graphql/queries';
import { Card, Descriptions, Tag, Spin, Alert, Table, Image, Tabs, Tooltip, Space, Button, message, Row, Col } from 'antd';
import { CustomerServiceOutlined, CopyOutlined } from '@ant-design/icons';
import './UserDetail.css';

const { TabPane } = Tabs;

const UserDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading: userLoading, error: userError, data: userData } = useQuery(getUser, {
    variables: { userId: id },
  });
  const { data: creditData } = useQuery(getUserCreditHistory, { 
    variables: { userId: id } 
  });
  const { data: videoData } = useQuery(dashboardUserVideos, { 
    variables: { userId: id } 
  });

  if (userLoading) return <Spin size="large" className="user-detail-loading" />;
  if (userError) return <Alert type="error" message={`Error: ${userError.message}`} />;

  const user = userData.getUser;
  const creditHistory = creditData?.getUserCreditHistory || [];
  const videos = videoData?.dashboardUserVideos || [];

  const truncateText = (text, maxLength = 30) => {
    if (!text) return '-';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const creditColumns = [
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      width: 250,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
    },
    {
      title: 'Credit ID',
      dataIndex: 'creditId',
      key: 'creditId',
      width: 250,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    },
  ];

  const videoColumns = [
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      width: 400,
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: 150,
      render: (url) => (
        <Image 
          src={url} 
          alt="Thumbnail" 
          style={{ width: '100px', height: '100px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 160,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    }
  ];

  const handleSupportClick = () => {
    if (user?.rcUserId) {
      navigate(`/support?rcUserId=${user.rcUserId}`);
    } else {
      message.warning('RevenueCat User ID not available');
    }
  };

  return (
    <div className="user-detail-container">
      <Card
        title={
          <Space>
            <span>User Details</span>
            <Button
              type="primary"
              icon={<CustomerServiceOutlined />}
              onClick={handleSupportClick}
              disabled={!user?.rcUserId}
            >
              Go to Support
            </Button>
          </Space>
        }
      >
        <div className="user-info-section">
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">RevenueCat ID</div>
                <div className="detail-value with-copy">
                  <span className="text-value">{user.rcUserId || '-'}</span>
                  {user.rcUserId && (
                    <Tooltip title="Copy ID">
                      <CopyOutlined
                        className="copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(user.rcUserId);
                          message.success('RevenueCat ID copied to clipboard');
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">User ID</div>
                <div className="detail-value with-copy">
                  <span className="text-value">{user.id}</span>
                  <Tooltip title="Copy ID">
                    <CopyOutlined
                      className="copy-icon"
                      onClick={() => {
                        navigator.clipboard.writeText(user.id);
                        message.success('User ID copied to clipboard');
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Store</div>
                <div className="detail-value">
                  <span className="text-value">{user.store || '-'}</span>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Role</div>
                <div className="detail-value">
                  <Tag color={user.role === 'ADMIN' ? 'green' : 'blue'}>
                    {user.role}
                  </Tag>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">User Type</div>
                <div className="detail-value">
                  <Tag color={user.isOrganic === null ? 'default' : user.isOrganic ? 'green' : 'blue'}>
                    {user.isOrganic === null ? 'Unknown' : user.isOrganic ? 'Organic' : 'Non-Organic'}
                  </Tag>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Premium Status</div>
                <div className="detail-value">
                  <Tag color={user.isPremium ? 'gold' : 'default'}>
                    {user.isPremium ? 'Premium' : 'Regular'}
                  </Tag>
                </div>
              </div>
            </Col>

            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Premium Expiry</div>
                <div className="detail-value">
                  <span className="text-value">
                    {user.premiumExpiryDate ? new Date(user.premiumExpiryDate).toLocaleString('tr-TR', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    }) : '-'}
                  </span>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Credits</div>
                <div className="detail-value">
                  <div className="stats-row">
                    <span className="stat-item">
                      <span className="stat-label">Total:</span>
                      <span className="stat-number">{user.totalCredit?.total || 0}</span>
                    </span>
                    <span className="stat-item">
                      <span className="stat-label">Used:</span>
                      <span className="stat-number">{user.usedCreditCount || 0}</span>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={8}>
              <div className="detail-item">
                <div className="detail-label">Moderations</div>
                <div className="detail-value">
                  <span className="text-value">{user.moderations?.total || 0}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Tabs defaultActiveKey="1" className="detail-tabs">
          <TabPane tab="Credit History" key="1">
            <Table
              columns={creditColumns}
              dataSource={creditHistory}
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} records`,
                pageSizeOptions: ['5', '10', '20', '50'],
                defaultPageSize: 5
              }}
              scroll={{ x: 'max-content' }}
            />
          </TabPane>
          
          <TabPane tab="Videos" key="2">
            <Table
              columns={videoColumns}
              dataSource={videos}
              rowKey="id"
              pagination={{
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} records`,
                pageSizeOptions: ['5', '10', '20', '50'],
                defaultPageSize: 5
              }}
              scroll={{ x: 'max-content' }}
              onRow={(record) => ({
                onClick: () => navigate(`/video/${record.id}`),
                style: { cursor: 'pointer' }
              })}
            />
          </TabPane>

          {user.moderations?.total > 0 && (
            <TabPane tab="Blacklist" key="3">
              <Table
                columns={[
                  { 
                    title: 'ID', 
                    dataIndex: 'id', 
                    key: 'id',
                    width: '10%'
                  },
                  { 
                    title: 'Prompt', 
                    dataIndex: 'prompt', 
                    key: 'prompt',
                    width: '25%',
                    render: (text) => (
                      <Tooltip title={text} placement="topLeft">
                        <span className="truncated-text">{truncateText(text)}</span>
                      </Tooltip>
                    )
                  },
                  { 
                    title: 'Input Image', 
                    dataIndex: 'inputImg', 
                    key: 'inputImg',
                    width: '15%',
                    render: (url) => url ? (
                      <Image
                        src={url}
                        alt="Input"
                        style={{ width: '80px', height: '80px', objectFit: 'cover' }}
                        preview={{
                          maskClassName: 'customize-mask',
                          mask: <div>View</div>
                        }}
                      />
                    ) : '-'
                  },
                  { 
                    title: 'Type', 
                    dataIndex: 'type', 
                    key: 'type',
                    width: '15%'
                  },
                  { 
                    title: 'Category', 
                    dataIndex: 'category', 
                    key: 'category',
                    width: '15%'
                  },
                  { 
                    title: 'Created At', 
                    dataIndex: 'createdAt', 
                    key: 'createdAt',
                    width: '15%',
                    render: (date) => new Date(date).toLocaleString('tr-TR', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })
                  },
                ]}
                dataSource={user.moderations.blackLists}
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} records`,
                  pageSizeOptions: ['5', '10', '20', '50'],
                  defaultPageSize: 5
                }}
              />
            </TabPane>
          )}
        </Tabs>
      </Card>
    </div>
  );
};

export default UserDetail; 