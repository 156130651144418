import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Row, Col, Statistic, Spin, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { 
  UsergroupAddOutlined,
  PlaySquareOutlined,
  CrownOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { Line, Pie, Area } from '@ant-design/plots';
import { 
  getBasicStats, 
  getCreditStats, 
  getDailyVideoStats,
  getVideoStatusStats,
  getVideoTypeStats 
} from '../../graphql/queries';
import './Dashboard.css';

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const today = dayjs();
  const [creditDateRange, setCreditDateRange] = useState([null, null]);
  const [videoStatsDateRange, setVideoStatsDateRange] = useState([null, null]);
  const [typeDateRange, setTypeDateRange] = useState([null, null]);
  
  // Basic Stats Query
  const { loading: basicStatsLoading, data: basicStatsData } = useQuery(getBasicStats);

  // Credit Stats Query with Date Range
  const { 
    loading: creditStatsLoading, 
    data: creditStatsData, 
    refetch: refetchCreditStats 
  } = useQuery(getCreditStats, {
    variables: {
      startDate: null,
      endDate: null
    },
    notifyOnNetworkStatusChange: true
  });

  // Daily Video Stats Query with Date Range
  const {
    loading: dailyVideoStatsLoading,
    data: dailyVideoStatsData,
    refetch: refetchDailyVideoStats
  } = useQuery(getDailyVideoStats, {
    variables: {
      startDate: null,
      endDate: null
    },
    notifyOnNetworkStatusChange: true
  });

  // Video Status Stats Query with Date Range
  const {
    loading: videoStatusStatsLoading,
    data: videoStatusStatsData,
    refetch: refetchVideoStatusStats
  } = useQuery(getVideoStatusStats, {
    variables: {
      startDate: null,
      endDate: null
    },
    notifyOnNetworkStatusChange: true
  });

  // Video Type Stats Query with Date Range
  const {
    loading: videoTypeStatsLoading,
    data: videoTypeStatsData,
    refetch: refetchVideoTypeStats
  } = useQuery(getVideoTypeStats, {
    variables: {
      startDate: null,
      endDate: null
    },
    notifyOnNetworkStatusChange: true
  });

  const handleCreditDateChange = async (dates) => {
    setCreditDateRange(dates);
    if (dates) {
      await refetchCreditStats({
        startDate: dates[0]?.format('YYYY-MM-DD'),
        endDate: dates[1]?.format('YYYY-MM-DD')
      });
    }
  };

  const handleVideoStatsDateChange = async (dates) => {
    setVideoStatsDateRange(dates);
    if (dates) {
      // Refetch both video stats queries with the same date range
      await Promise.all([
        refetchDailyVideoStats({
          startDate: dates[0]?.format('YYYY-MM-DD'),
          endDate: dates[1]?.format('YYYY-MM-DD')
        }),
        refetchVideoStatusStats({
          startDate: dates[0]?.format('YYYY-MM-DD'),
          endDate: dates[1]?.format('YYYY-MM-DD')
        })
      ]);
    }
  };

  const handleTypeDateChange = async (dates) => {
    setTypeDateRange(dates);
    if (dates) {
      await refetchVideoTypeStats({
        startDate: dates[0]?.format('YYYY-MM-DD'),
        endDate: dates[1]?.format('YYYY-MM-DD')
      });
    }
  };

  if (basicStatsLoading) return <Spin size="large" className="dashboard-loading" />;

  const basicStats = basicStatsData?.getBasicStats || {};
  const creditStats = creditStatsData?.getCreditStats || [];
  const dailyVideoStats = dailyVideoStatsData?.getDailyVideoStats || [];
  const videoStatusStats = videoStatusStatsData?.getVideoStatusStats || {};
  const videoTypeStats = videoTypeStatsData?.getVideoTypeStats || {};

  // Transform credit stats data for Area chart
  const transformedCreditData = [];
  creditStats.forEach(stat => {
    const { __typename, ...creditCounts } = stat.creditCounts;
    Object.entries(creditCounts).forEach(([type, value]) => {
      transformedCreditData.push({
        date: stat.date,
        type: type.charAt(0).toUpperCase() + type.slice(1),
        value: value
      });
    });
  });

  // Credit Stats Area Chart Configuration
  const areaConfig = {
    data: transformedCreditData,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    color: ['#1890ff', '#52c41a', '#faad14', '#f5222d', '#722ed1', '#13c2c2', '#eb2f96'],
    areaStyle: {
      fillOpacity: 0.6,
    },
    legend: {
      position: 'top',
    },
    smooth: true,
    xAxis: {
      type: 'time',
      mask: 'MM/DD/YYYY',
    },
    tooltip: {
      shared: true,
      showCrosshairs: true,
    },
  };

  // Pie Chart Configuration
  const pieConfig = {
    data: [
      { type: 'Failed', value: videoStatusStats?.failed || 0 },
      { type: 'Running', value: videoStatusStats?.running || 0 },
      { type: 'In Queue', value: videoStatusStats?.in_queue || 0 },
      { type: 'Throttled', value: videoStatusStats?.throttled || 0 },
      { type: 'Succeeded', value: videoStatusStats?.succeeded || 0 },
      { type: 'Pending', value: videoStatusStats?.pending || 0 },
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'element-active' }],
    legend: {
      position: 'right',
    },
    color: ['#ff4d4f', '#faad14', '#1890ff', '#purple', '#52c41a', '#orange'],
  };

  // Line Chart Configuration
  const lineConfig = {
    data: dailyVideoStats,
    xField: 'date',
    yField: 'count',
    point: {
      size: 5,
      shape: 'diamond',
    },
    label: {
      style: {
        fill: '#aaa',
      },
    },
  };

  // Video Type Pie Chart Configuration
  const typeConfig = {
    data: [
      { type: 'Image to Video', value: videoTypeStats?.img2Video || 0 },
      { type: 'Text to Video', value: videoTypeStats?.text2Video || 0 },
      { type: 'Tool Predict', value: videoTypeStats?.toolPredict || 0 },
      { type: 'Story Board', value: videoTypeStats?.storyBoard || 0 },
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'element-active' }],
    legend: {
      position: 'right',
    },
    color: ['#1890ff', '#52c41a', '#722ed1', '#faad14'],
  };

  const renderChart = (loading, chart) => {
    if (loading) {
      return (
        <div style={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin />
        </div>
      );
    }
    return chart;
  };

  const statsCards = [
    {
      title: 'Total Users',
      value: basicStats.totalUsers || 0,
      icon: <UsergroupAddOutlined />,
      color: '#1890ff'
    },
    {
      title: 'Total Videos',
      value: basicStats.totalVideos || 0,
      icon: <PlaySquareOutlined />,
      color: '#52c41a'
    },
    {
      title: 'Active Subscriptions',
      value: basicStats.activeSubscriptions || 0,
      icon: <CrownOutlined />,
      color: '#faad14'
    },
    {
      title: 'Last Video',
      value: basicStats.lastVideoDate ? new Date(basicStats.lastVideoDate).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) : '-',
      icon: <FieldTimeOutlined />,
      color: '#722ed1'
    }
  ];

  const videoTypeCards = [
    {
      title: 'Image to Video',
      value: videoTypeStats?.img2Video || 0,
      color: '#1890ff'
    },
    {
      title: 'Text to Video',
      value: videoTypeStats?.text2Video || 0,
      color: '#52c41a'
    },
    {
      title: 'Tool Predict',
      value: videoTypeStats?.toolPredict || 0,
      color: '#722ed1'
    },
    {
      title: 'Story Board',
      value: videoTypeStats?.storyBoard || 0,
      color: '#faad14'
    }
  ];

  // Calculate total from non-zero values
  const calculateTotal = () => {
    return videoTypeCards.reduce((sum, type) => sum + type.value, 0);
  };

  const total = calculateTotal();

  return (
    <div className="dashboard-container">
      {/* Basic Stats Cards */}
      <Row gutter={[16, 16]}>
        {statsCards.map((stat, index) => (
          <Col xs={24} sm={12} lg={6} key={index}>
            <Card bodyStyle={{ padding: '12px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '4px' }}>
                {React.cloneElement(stat.icon, { 
                  style: { fontSize: '20px', color: stat.color } 
                })}
                <span style={{ fontSize: '14px', opacity: 0.65 }}>
                  {stat.title}
                </span>
              </div>
              <div style={{ fontSize: '20px', fontWeight: '500' }}>
                {stat.value}
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Video Type Stats Section */}
      <Row gutter={[16, 16]} style={{ marginTop: '24px' }}>
        <Col span={24}>
          <Card 
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Video Types</span>
                <RangePicker 
                  onChange={handleTypeDateChange}
                  format="YYYY-MM-DD"
                  value={typeDateRange}
                  size="small"
                />
              </div>
            }
            bodyStyle={{ padding: '12px' }}
          >
            <Row gutter={[16, 16]}>
              {videoTypeCards.map((type, index) => (
                <Col xs={24} sm={12} lg={6} key={index}>
                  <Card bodyStyle={{ padding: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontSize: '14px', opacity: 0.65, marginBottom: '4px' }}>
                        {type.title}
                      </span>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                        <span style={{ fontSize: '20px', fontWeight: '500', color: type.color }}>
                          {type.value}
                        </span>
                        <span style={{ fontSize: '12px', opacity: 0.45 }}>
                          {total > 0 && type.value > 0 ? `${((type.value / total) * 100).toFixed(1)}%` : '0%'}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
      </Row>

      {/* Charts */}
      <Row gutter={[24, 24]} className="dashboard-charts">
        <Col xs={24} lg={12}>
          <Card 
            title="Video Status Distribution"
            extra={
              <RangePicker 
                onChange={handleVideoStatsDateChange}
                format="YYYY-MM-DD"
                value={videoStatsDateRange}
              />
            }
          >
            {renderChart(videoStatusStatsLoading, <Pie {...pieConfig} />)}
          </Card>
        </Col>
        <Col xs={24} lg={12}>
          <Card 
            title="Daily Video Count"
            extra={
              <RangePicker 
                onChange={handleVideoStatsDateChange}
                format="YYYY-MM-DD"
                value={videoStatsDateRange}
              />
            }
          >
            {renderChart(dailyVideoStatsLoading, <Line {...lineConfig} />)}
          </Card>
        </Col>
      </Row>

      {/* Credit Stats Chart */}
      <Row gutter={[24, 24]} className="dashboard-charts">
        <Col xs={24}>
          <Card 
            title="Credit Distribution Over Time"
            extra={
              <RangePicker 
                onChange={handleCreditDateChange}
                format="YYYY-MM-DD"
                value={creditDateRange}
              />
            }
          >
            {renderChart(creditStatsLoading, <Area {...areaConfig} height={400} />)}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard; 