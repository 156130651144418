import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Card, Progress, Tag, Image, Tooltip, Typography, Select } from 'antd';
import { getAllvideos } from '../../graphql/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './Videos.css';

const { Paragraph } = Typography;
const { Option } = Select;

const Videos = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState({
    current: parseInt(searchParams.get('page')) || 1,
    pageSize: parseInt(searchParams.get('pageSize')) || 10,
  });
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);

  const { loading, data } = useQuery(getAllvideos, {
    variables: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      status: selectedStatus,
      model: selectedModel,
    },
  });

  useEffect(() => {
    const params = new URLSearchParams();
    params.set('page', pagination.current.toString());
    params.set('pageSize', pagination.pageSize.toString());
    if (selectedStatus) params.set('status', selectedStatus);
    if (selectedModel) params.set('model', selectedModel);
    setSearchParams(params);
  }, [pagination, selectedStatus, selectedModel, setSearchParams]);

  const getStatusColor = (status) => {
    const colors = {
      SUCCEEDED: 'green',
      FAILED: 'red',
      RUNNING: 'blue',
      IN_QUE: 'orange',
      PENDING: 'purple',
      THROTTLED: 'gold'
    };
    return colors[status] || 'default';
  };

  const columns = [
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnailUrl',
      width: 90,
      render: (url) => (
        <Image
          src={url}
          alt="Thumbnail"
          style={{ width: '70px', height: '70px', objectFit: 'cover' }}
          preview={{
            maskClassName: 'customize-mask',
            mask: <div>View</div>
          }}
        />
      ),
    },
    {
      title: 'Prompt',
      dataIndex: 'prompt',
      key: 'prompt',
      width: 180,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0, maxWidth: 160 }}>
            {text}
          </Paragraph>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) => {
        const displayText = {
          SUCCEEDED: 'Succeeded',
          FAILED: 'Failed',
          RUNNING: 'Running',
          IN_QUE: 'In Queue',
          PENDING: 'Pending',
          THROTTLED: 'Throttled'
        };
        return (
          <Tag color={getStatusColor(status)}>
            {displayText[status] || status}
          </Tag>
        );
      },
      filterDropdown: () => (
        <Select
          style={{ width: 120, margin: 8 }}
          value={selectedStatus}
          onChange={(value) => {
            setSelectedStatus(value);
            setPagination(prev => ({ ...prev, current: 1 }));
          }}
          allowClear
          placeholder="Filter status"
        >
          <Option value="RUNNING">Running</Option>
          <Option value="FAILED">Failed</Option>
          <Option value="SUCCEEDED">Succeeded</Option>
          <Option value="IN_QUE">In Queue</Option>
          <Option value="PENDING">Pending</Option>
          <Option value="THROTTLED">Throttled</Option>
        </Select>
      ),
      filtered: !!selectedStatus,
    },
    {
      title: 'Progress',
      dataIndex: 'progressRatio',
      key: 'progressRatio',
      width: 200,
      render: (progress) => (
        <Progress 
          percent={progress || 0}
          size="small" 
          status={progress === 100 ? 'success' : 'active'}
          strokeColor={{
            '0%': '#108ee9',
            '100%': '#87d068',
          }}
        />
      ),
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      width: 100,
      filterDropdown: () => (
        <Select
          style={{ width: 120, margin: 8 }}
          value={selectedModel}
          onChange={(value) => {
            setSelectedModel(value);
            setPagination(prev => ({ ...prev, current: 1 }));
          }}
          allowClear
          placeholder="Filter model"
        >
          <Option value="GEN2">Gen2</Option>
          <Option value="GEN3">Gen3</Option>
          <Option value="LUMA">Luma</Option>
          <Option value="HAILUOAI">HailuoAI</Option>
          <Option value="SORA">Sora</Option>
        </Select>
      ),
      filtered: !!selectedModel,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 100,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }),
    },
  ];

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleRowClick = (record) => {
    navigate(`/video/${record.id}`);
  };

  return (
    <div className="videos-container">
      <Card title="Videos">
        <Table
          columns={columns}
          dataSource={data?.getAllVideos?.videos || []}
          loading={loading}
          rowKey="id"
          pagination={{
            ...pagination,
            total: data?.getAllVideos?.totalCount,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} videos`,
          }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
            style: { cursor: 'pointer' }
          })}
        />
      </Card>
    </div>
  );
};

export default Videos; 