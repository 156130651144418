import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { getExploreVideo, getAllStyles, getCategories } from '../../graphql/queries';
import { updateExplore, deleteExplore, getSignedUrl, getObjectUrl } from '../../graphql/mutation';
import { 
  Card, 
  Form, 
  Input, 
  Select, 
  Upload, 
  Button, 
  Space, 
  message, 
  Row, 
  Col,
  Modal,
  Divider
} from 'antd';
import { UploadOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { uploadToR2 } from '../../utils/uploadToR2';
import countries from '../../countries';
import './ExploreDetail.css';

const { Option } = Select;

const ExploreDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [lowResVideo, setLowResVideo] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [gifFile, setGifFile] = useState(null);
  const [webmFile, setWebmFile] = useState(null);

  const { data: exploreData, loading: exploreLoading } = useQuery(getExploreVideo, {
    variables: { exploreId: id },
    onCompleted: (data) => {
      const explore = data?.getExploreVideo;
      if (explore) {
        form.setFieldsValue({
          prompt: explore.prompt,
          style: explore.style,
          categoryId: explore.category?.id,
          aspectRatio: explore.aspectRatio,
          motionScore: explore.motionScore,
          region: explore.region || [],
          exRegion: explore.exRegion || [],
        });
      }
    }
  });

  const { data: stylesData } = useQuery(getAllStyles);
  const { data: categoriesData } = useQuery(getCategories);
  const [updateExploreMutation] = useMutation(updateExplore);
  const [deleteExploreMutation] = useMutation(deleteExplore);
  const [getSignedUrlMutation] = useMutation(getSignedUrl);
  const [getObjectUrlMutation] = useMutation(getObjectUrl);

  const handleUpdate = async (values) => {
    try {
      setLoading(true);

      const files = {
        ...(videoFile && { video: videoFile }),
        ...(lowResVideo && { lowRes: lowResVideo }),
        ...(thumbnailFile && { thumbnail: thumbnailFile }),
        ...(gifFile && { gif: gifFile }),
        ...(webmFile && { webm: webmFile })
      };

      let urls = {
        video: exploreData?.getExploreVideo?.url,
        lowRes: exploreData?.getExploreVideo?.lowResolutionUrl,
        thumbnail: exploreData?.getExploreVideo?.thumbnailUrl,
        gif: exploreData?.getExploreVideo?.gif,
        webm: exploreData?.getExploreVideo?.webmUrl,
      };

      if (Object.keys(files).length > 0) {
        // Get signed URLs for new files
        const signedUrls = {};
        for (const [type, file] of Object.entries(files)) {
          const { data } = await getSignedUrlMutation({
            variables: { fileName: file.name }
          });
          signedUrls[type] = data.getSignedUrl;
        }

        // Upload new files
        const uploadPromises = Object.entries(files).map(([type, file]) => 
          uploadToR2(file, signedUrls[type].url, signedUrls[type].contentType)
        );
        await Promise.all(uploadPromises);

        // Get permanent URLs for new files
        for (const [type, urlData] of Object.entries(signedUrls)) {
          const { data } = await getObjectUrlMutation({
            variables: { key: urlData.id, eTag: 'etag' }
          });
          urls[type] = data.getObjectUrl;
        }
      }

      await updateExploreMutation({
        variables: {
          exploreId: id,
          prompt: values.prompt,
          url: urls.video,
          lowResolutionUrl: urls.lowRes,
          thumbnailUrl: urls.thumbnail,
          gif: urls.gif,
          webmUrl: urls.webm,
          motionScore: null,
          style: values.style,
          aspectRatio: values.aspectRatio,
          categoryId: values.categoryId,
          region: values.region || [],
          exRegion: values.exRegion || [],
        }
      });

      message.success('Explore updated successfully');
      navigate('/explore');
    } catch (error) {
      message.error(`Failed to update explore: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this explore?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete',
      okType: 'danger',
      cancelText: 'No, cancel',
      onOk: async () => {
        try {
          await deleteExploreMutation({
            variables: { exploreId: id }
          });
          message.success('Explore deleted successfully');
          navigate('/explore');
        } catch (error) {
          message.error(`Failed to delete explore: ${error.message}`);
        }
      }
    });
  };

  if (exploreLoading) {
    return <div className="loading-container">Loading...</div>;
  }

  const explore = exploreData?.getExploreVideo;

  return (
    <div className="explore-detail-container">
      <Card title="Edit Explore">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
        >
          <Row gutter={24}>
            <Col span={16}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Main Video</h3>
                    <div className="media-preview">
                      <video src={explore?.url} controls />
                      <Upload
                        beforeUpload={(file) => {
                          setVideoFile(file);
                          return false;
                        }}
                        maxCount={1}
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Change</Button>
                      </Upload>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Low Resolution Video</h3>
                    <div className="media-preview">
                      <video src={explore?.lowResolutionUrl} controls />
                      <Upload
                        beforeUpload={(file) => {
                          setLowResVideo(file);
                          return false;
                        }}
                        maxCount={1}
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Change</Button>
                      </Upload>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>WebM Video</h3>
                    <div className="media-preview">
                      <video src={explore?.webmUrl} controls />
                      <Upload
                        beforeUpload={(file) => {
                          setWebmFile(file);
                          return false;
                        }}
                        maxCount={1}
                        accept="video/webm"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Change</Button>
                      </Upload>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Thumbnail</h3>
                    <div className="media-preview">
                      <img src={explore?.thumbnailUrl} alt="Thumbnail" />
                      <Upload
                        beforeUpload={(file) => {
                          setThumbnailFile(file);
                          return false;
                        }}
                        maxCount={1}
                        accept="image/*"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Change</Button>
                      </Upload>
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>GIF Preview</h3>
                    <div className="media-preview">
                      <img src={explore?.gif} alt="GIF" />
                      <Upload
                        beforeUpload={(file) => {
                          setGifFile(file);
                          return false;
                        }}
                        maxCount={1}
                        accept="image/gif"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Change</Button>
                      </Upload>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <div className="form-section">
                <Form.Item
                  name="prompt"
                  label="Prompt"
                >
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  name="style"
                  label="Style"
                >
                  <Select>
                    {stylesData?.getAllStyles?.map(style => (
                      <Option key={style.value} value={style.value}>{style.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="categoryId"
                  label="Category"
                >
                  <Select>
                    {categoriesData?.getCategories?.map(category => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="aspectRatio"
                  label="Aspect Ratio"
                >
                  <Select>
                    {['WIDESCREEN', 'PORTRAIT', 'SQUARE'].map(ratio => (
                      <Option key={ratio} value={ratio}>{ratio}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item 
                  name="region" 
                  label="Regions"
                  tooltip="Select countries to include. Cannot be used together with Excluded Regions."
                  dependencies={['exRegion']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const exRegion = getFieldValue('exRegion');
                        if (exRegion?.length > 0 && value?.length > 0) {
                          return Promise.reject('You cannot select both Region and Excluded Region');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Search and select countries to include"
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    maxTagCount={5}
                    maxTagTextLength={20}
                    allowClear
                  >
                    {countries.map(country => (
                      <Option key={country["alpha-2"]} value={country["alpha-2"]}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item 
                  name="exRegion" 
                  label="Excluded Regions"
                  tooltip="Select countries to exclude. Cannot be used together with Regions."
                  dependencies={['region']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const region = getFieldValue('region');
                        if (region?.length > 0 && value?.length > 0) {
                          return Promise.reject('You cannot select both Region and Excluded Region');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Search and select countries to exclude"
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    maxTagCount={5}
                    maxTagTextLength={20}
                    allowClear
                  >
                    {countries.map(country => (
                      <Option key={country["alpha-2"]} value={country["alpha-2"]}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Space>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      icon={<SaveOutlined />}
                      loading={loading}
                    >
                      Update
                    </Button>
                    <Button 
                      danger 
                      type="primary"
                      icon={<DeleteOutlined />}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default ExploreDetail; 