import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { getVideo } from '../../graphql/queries';
import { deleteVideo, updateVideoStatus, updateFeedStatus } from '../../graphql/mutation';
import { 
  Card, 
  Descriptions, 
  Tag, 
  Spin, 
  Row, 
  Col, 
  Button, 
  Select, 
  Alert,
  Space,
  Divider,
  Typography,
  Modal,
  message 
} from 'antd';
import { 
  DeleteOutlined, 
  SaveOutlined, 
  PlayCircleOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  WarningOutlined
} from '@ant-design/icons';
import './VideoDetail.css';

const { Option } = Select;
const { Title, Text } = Typography;

const statusOptions = [
  { value: 'RUNNING', label: 'Running', icon: <PlayCircleOutlined />, color: 'blue' },
  { value: 'SUCCEEDED', label: 'Succeeded', icon: <CheckCircleOutlined />, color: 'green' },
  { value: 'FAILED', label: 'Failed', icon: <CloseCircleOutlined />, color: 'red' },
  { value: 'IN_QUE', label: 'In Queue', icon: <ClockCircleOutlined />, color: 'orange' },
  { value: 'PENDING', label: 'Pending', icon: <ClockCircleOutlined />, color: 'purple' },
  { value: 'THROTTLED', label: 'Throttled', icon: <WarningOutlined />, color: 'gold' }
];

const feedStatusOptions = [
  { value: 'PENDING', label: 'Pending', color: 'purple' },
  { value: 'CONFIRMED', label: 'Confirmed', color: 'green' },
  { value: 'REJECTED', label: 'Rejected', color: 'red' }
];

const VideoDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [videoStatus, setVideoStatus] = useState('');
  const [feedStatus, setFeedStatus] = useState('');
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [showNotification, setShowNotification] = useState(false);

  const { loading, error, data, refetch } = useQuery(getVideo, {
    variables: { videoId: id },
  });

  const [updateVideoStatusMutation] = useMutation(updateVideoStatus);
  const [updateFeedMutation] = useMutation(updateFeedStatus);
  const [deleteVideoMutation] = useMutation(deleteVideo);

  useEffect(() => {
    if (data?.getVideo) {
      setVideoStatus(data.getVideo.status);
      setFeedStatus(data.getVideo.feed?.status);
    }
  }, [data]);

  useEffect(() => {
    if (showNotification) {
      const timer = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showNotification]);

  const handleUpdateVideoStatus = async () => {
    try {
      await updateVideoStatusMutation({
        variables: { videoId: id, status: videoStatus }
      });
      message.success('Video status updated successfully');
      refetch();
    } catch (err) {
      message.error('Failed to update video status');
    }
  };

  const handleUpdateFeedStatus = async () => {
    try {
      await updateFeedMutation({
        variables: { feedId: data.getVideo.feed.id, status: feedStatus }
      });
      message.success('Feed status updated successfully');
      refetch();
    } catch (err) {
      message.error('Failed to update feed status');
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this video?',
      content: 'This action cannot be undone.',
      okText: 'Yes, Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteVideoMutation({ variables: { videoId: id } });
          message.success('Video deleted successfully');
          navigate('/videos');
        } catch (err) {
          message.error('Failed to delete video');
        }
      },
    });
  };

  if (loading) return <Spin size="large" className="video-detail-loading" />;
  if (error) return <Alert type="error" message={error.message} />;

  const video = data.getVideo;

  const getResolutionText = (resolution) => {
    const resolutionMap = {
      'NORMAL': '480p',
      'HIGH': '720p',
      'ULTRA': '1080p'
    };
    return resolutionMap[resolution] || resolution || '-';
  };

  const getSecondsText = (seconds) => {
    const secondsMap = {
      'ONE': '5',
      'TWO': '10',
      'THREE': '15',
      'FOUR': '20'
    };
    return secondsMap[seconds] || seconds || '-';
  };

  return (
    <div className="video-detail-container">
      {showNotification && (
        <div className="notification-container">
          <Alert
            message={notification.message}
            type={notification.type}
            showIcon
            closable
            onClose={() => setShowNotification(false)}
          />
        </div>
      )}
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Card className="info-card">
            <Title level={4}>Video Information</Title>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="ID">{video.id}</Descriptions.Item>
              <Descriptions.Item label="User ID">
                <Button 
                  type="link" 
                  onClick={() => navigate(`/user/${video.userId}`)}
                  style={{ padding: 0 }}
                >
                  {video.userId}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Prompt">{video.prompt}</Descriptions.Item>
              <Descriptions.Item label="Model">{video.model}</Descriptions.Item>
              {video.aspectRatio && (
                <Descriptions.Item label="Aspect Ratio">
                  <Tag color="blue">{video.aspectRatio}</Tag>
                </Descriptions.Item>
              )}
              {video.resolution && (
                <Descriptions.Item label="Resolution">
                  <Tag color="blue">{getResolutionText(video.resolution)}</Tag>
                </Descriptions.Item>
              )}
              {video.seconds && (
                <Descriptions.Item label="Duration">
                  <Tag color="blue">{getSecondsText(video.seconds)} seconds</Tag>
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Created At">
                {new Date(video.createdAt).toLocaleString('tr-TR', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </Descriptions.Item>
              <Descriptions.Item label="Updated At">
                {new Date(video.updatedAt).toLocaleString('tr-TR', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag color={statusOptions.find(s => s.value === video.status)?.color}>
                  {statusOptions.find(s => s.value === video.status)?.icon} {video.status}
                </Tag>
              </Descriptions.Item>
              {video.errorReason && (
                <Descriptions.Item label="Error">
                  <Text type="danger">{video.errorReason}</Text>
                </Descriptions.Item>
              )}
            </Descriptions>

            <Divider />

            <Space direction="vertical" style={{ width: '100%' }} size="large">
              <div>
                <Title level={5}>Update Video Status</Title>
                <Space>
                  <Select
                    value={videoStatus}
                    onChange={setVideoStatus}
                    style={{ width: 200 }}
                  >
                    {statusOptions.map(option => (
                      <Option key={option.value} value={option.value}>
                        <Space>
                          {option.icon}
                          {option.label}
                        </Space>
                      </Option>
                    ))}
                  </Select>
                  <Button 
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={handleUpdateVideoStatus}
                  >
                    Update Status
                  </Button>
                </Space>
              </div>

              {video.feed && (
                <div>
                  <Title level={5}>Update Feed Status</Title>
                  <Space>
                    <Select
                      value={feedStatus}
                      onChange={setFeedStatus}
                      style={{ width: 200 }}
                    >
                      {feedStatusOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                          <Tag color={option.color}>{option.label}</Tag>
                        </Option>
                      ))}
                    </Select>
                    <Button 
                      type="primary"
                      icon={<SaveOutlined />}
                      onClick={handleUpdateFeedStatus}
                    >
                      Update Feed
                    </Button>
                  </Space>
                </div>
              )}

              <Button 
                danger 
                type="primary" 
                icon={<DeleteOutlined />}
                onClick={handleDelete}
                block
              >
                Delete Video
              </Button>
            </Space>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Video Preview" className="preview-card">
            <div className="video-container">
              {video.url ? (
                <div className="video-wrapper">
                  <video 
                    controls 
                    src={video.url}
                    poster={video.thumbnailUrl}
                    className="video-player"
                    preload="metadata"
                  />
                </div>
              ) : (
                <div className="thumbnail-wrapper">
                  <img 
                    src={video.thumbnailUrl} 
                    alt="Video Thumbnail" 
                    className="video-thumbnail"
                  />
                </div>
              )}
            </div>

            {video.inputImageUrl && (
              <div className="input-image-section">
                <h3>Input Image</h3>
                <div className="input-image-wrapper">
                  <img 
                    src={video.inputImageUrl} 
                    alt="Input" 
                    className="input-image"
                  />
                </div>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default VideoDetail; 