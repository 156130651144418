import React from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import { useMutation } from '@apollo/client';
import { 
  createRunwayAccount,
  createHailuoaiAccount,
  createSoraAccount
} from '../../graphql/mutation';
import './Account.css';

const { Option } = Select;

const REGIONS = [
  { value: 'US', label: 'United States' },
  { value: 'EU', label: 'Europe' },
  { value: 'AP', label: 'Asia Pacific' },
  { value: 'SA', label: 'South America' },
  { value: 'AF', label: 'Africa' }
];

const CreateAccount = ({ open, onClose, onSubmit, accountType }) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSubmit(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const getFormItems = () => {
    const baseItems = [
      {
        name: 'accountName',
        label: 'Account Name',
        rules: [{ required: true, message: 'Please input account name!' }],
        input: <Input />
      },
      {
        name: 'token',
        label: 'Token',
        rules: [{ required: true, message: 'Please input token!' }],
        input: <Input />
      },
      {
        name: 'regions',
        label: 'Regions',
        rules: [{ required: true, message: 'Please select at least one region!' }],
        input: (
          <Select
            mode="multiple"
            placeholder="Select regions"
            style={{ width: '100%' }}
          >
            {REGIONS.map(region => (
              <Option key={region.value} value={region.value}>
                {region.label}
              </Option>
            ))}
          </Select>
        )
      }
    ];

    // Only add teamId field for Runway accounts
    if (accountType === 'RUNWAY') {
      return [
        ...baseItems,
        {
          name: 'teamId',
          label: 'Team ID',
          rules: [{ required: true, message: 'Please input team ID!' }],
          input: <Input />
        }
      ];
    }

    return baseItems;
  };

  return (
    <Modal
      title={`Create ${accountType} Account`}
      open={open}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={handleSubmit}
      okText="Create"
    >
      <Form
        form={form}
        layout="vertical"
      >
        {getFormItems().map(item => (
          <Form.Item
            key={item.name}
            name={item.name}
            label={item.label}
            rules={item.rules}
          >
            {item.input}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

export default CreateAccount; 