import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { getAllStyles, getCategories } from '../../graphql/queries';
import { createExplore, getObjectUrl, getSignedUrl } from '../../graphql/mutation';
import { 
  Card, 
  Form, 
  Input, 
  Select, 
  Upload, 
  Button, 
  Space, 
  message, 
  Row, 
  Col,
  Image 
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { uploadToR2 } from '../../utils/uploadToR2';
import  countries  from '../../countries';
import './CreateExplore.css';

const { Option } = Select;



const CreateExplore = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState(null);
  const [lowResVideo, setLowResVideo] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [gifFile, setGifFile] = useState(null);
  const [webmFile, setWebmFile] = useState(null);
  const [previews, setPreviews] = useState({
    video: null,
    lowRes: null,
    thumbnail: null,
    gif: null,
    webm: null
  });

  const { data: stylesData } = useQuery(getAllStyles);
  const { data: categoriesData } = useQuery(getCategories);
  const [createExploreMutation] = useMutation(createExplore);
  const [getSignedUrlMutation] = useMutation(getSignedUrl);
  const [getObjectUrlMutation] = useMutation(getObjectUrl);

  const handleFileChange = (type, file) => {
    if (file) {
      const isLt100M = file.size / 1024 / 1024 < 100;
      if (!isLt100M) {
        message.error(`${type} must be smaller than 100MB!`);
        return false;
      }

      const previewUrl = URL.createObjectURL(file);
      switch(type) {
        case 'video':
          setVideoFile(file);
          setPreviews(prev => ({ ...prev, video: previewUrl }));
          break;
        case 'lowRes':
          setLowResVideo(file);
          setPreviews(prev => ({ ...prev, lowRes: previewUrl }));
          break;
        case 'thumbnail':
          setThumbnailFile(file);
          setPreviews(prev => ({ ...prev, thumbnail: previewUrl }));
          break;
        case 'gif':
          setGifFile(file);
          setPreviews(prev => ({ ...prev, gif: previewUrl }));
          break;
        case 'webm':
          setWebmFile(file);
          setPreviews(prev => ({ ...prev, webm: previewUrl }));
          break;
        default:
          break;
      }
    }
    return false;
  };
  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      // Dosya kontrolü kaldırıldı
      const files = {
        ...(videoFile && { video: videoFile }),
        ...(lowResVideo && { lowRes: lowResVideo }),
        ...(thumbnailFile && { thumbnail: thumbnailFile }),
        ...(gifFile && { gif: gifFile }),
        ...(webmFile && { webm: webmFile })
      };

      const signedUrls = {};
      const urls = {};

      // Sadece yüklenen dosyalar için işlem yap
      if (Object.keys(files).length > 0) {
        for (const [type, file] of Object.entries(files)) {
          const { data } = await getSignedUrlMutation({
            variables: { fileName: file.name }
          });
          signedUrls[type] = data.getSignedUrl;
        }

        const uploadPromises = Object.entries(files).map(([type, file]) => 
          uploadToR2(file, signedUrls[type].url, signedUrls[type].contentType)
        );
        await Promise.all(uploadPromises);

        for (const [type, urlData] of Object.entries(signedUrls)) {
          const { data } = await getObjectUrlMutation({
            variables: { key: urlData.id, eTag: 'etag' }
          });
          urls[type] = data.getObjectUrl;
        }
      }
console.log(values.regions)
      await createExploreMutation({
        variables: {
          prompt: values.prompt || null,
          url: urls.video || null,
          lowResolutionUrl: urls.lowRes || null,
          thumbnailUrl: urls.thumbnail || null,
          gif: urls.gif || null,
          webmUrl: urls.webm || null,
          motionScore: null,
          style: values.style || null,
          aspectRatio: values.aspectRatio || null,
          categoryId: values.categoryId || null,
          region: values.region || [],
          exRegion: values.exRegion || []
        }
      });

      message.success('Explore created successfully');
      navigate('/explore');
    } catch (error) {
      message.error(`Failed to create explore: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="explore-detail-container">
      <Card title="Create Explore">
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={24}>
            <Col span={16}>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Main Video</h3>
                    <div className="media-preview">
                      <Upload
                        beforeUpload={(file) => {
                          handleFileChange('video', file);
                          return false;
                        }}
                        maxCount={1}
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Upload Video</Button>
                      </Upload>
                      {previews.video && (
                        <div className="preview-container">
                          <video
                            src={previews.video}
                            controls
                            style={{ width: '100%', marginTop: '8px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Low Resolution Video</h3>
                    <div className="media-preview">
                      <Upload
                        beforeUpload={(file) => {
                          handleFileChange('lowRes', file);
                          return false;
                        }}
                        maxCount={1}
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Upload Low Res Video</Button>
                      </Upload>
                      {previews.lowRes && (
                        <div className="preview-container">
                          <video
                            src={previews.lowRes}
                            controls
                            style={{ width: '100%', marginTop: '8px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>Thumbnail</h3>
                    <div className="media-preview">
                      <Upload
                        beforeUpload={(file) => {
                          handleFileChange('thumbnail', file);
                          return false;
                        }}
                        maxCount={1}
                        accept="image/*"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Upload Thumbnail</Button>
                      </Upload>
                      {previews.thumbnail && (
                        <div className="preview-container">
                          <img
                            src={previews.thumbnail}
                            alt="Thumbnail Preview"
                            style={{ width: '100%', marginTop: '8px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>GIF</h3>
                    <div className="media-preview">
                      <Upload
                        beforeUpload={(file) => {
                          handleFileChange('gif', file);
                          return false;
                        }}
                        maxCount={1}
                        accept="image/gif"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Upload GIF</Button>
                      </Upload>
                      {previews.gif && (
                        <div className="preview-container">
                          <img
                            src={previews.gif}
                            alt="GIF Preview"
                            style={{ width: '100%', marginTop: '8px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>

                <Col span={8}>
                  <div className="media-preview-section">
                    <h3>WebM</h3>
                    <div className="media-preview">
                      <Upload
                        beforeUpload={(file) => {
                          handleFileChange('webm', file);
                          return false;
                        }}
                        maxCount={1}
                        accept="video/webm"
                        className="upload-button"
                      >
                        <Button icon={<UploadOutlined />}>Upload WebM</Button>
                      </Upload>
                      {previews.webm && (
                        <div className="preview-container">
                          <video
                            src={previews.webm}
                            controls
                            style={{ width: '100%', marginTop: '8px' }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <div className="form-section">
                <Form.Item name="prompt" label="Prompt">
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item name="style" label="Style">
                  <Select>
                    {stylesData?.getAllStyles?.map(style => (
                      <Option key={style.value} value={style.value}>{style.name}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="categoryId" label="Category">
                  <Select>
                    {categoriesData?.getCategories?.map(category => (
                      <Option key={category.id} value={category.id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="aspectRatio" label="Aspect Ratio">
                  <Select>
                    {['WIDESCREEN', 'PORTRAIT', 'SQUARE'].map(ratio => (
                      <Option key={ratio} value={ratio}>{ratio}</Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item 
                  name="region" 
                  label="Regions"
                  dependencies={['exRegion']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const exRegion = getFieldValue('exRegion');
                        if (exRegion?.length > 0 && value?.length > 0) {
                          return Promise.reject('You cannot select both Region and Excluded Region');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Search and select countries to include"
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    maxTagCount={5}
                    maxTagTextLength={20}
                  >
                    {countries.map(country => (
                      <Option key={country["alpha-2"]} value={country["alpha-2"]}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item 
                  name="exRegion" 
                  label="Excluded Regions"
                  dependencies={['region']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const region = getFieldValue('region');
                        if (region?.length > 0 && value?.length > 0) {
                          return Promise.reject('You cannot select both Region and Excluded Region');
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Search and select countries to exclude"
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    maxTagCount={5}
                    maxTagTextLength={20}
                  >
                    {countries.map(country => (
                      <Option key={country["alpha-2"]} value={country["alpha-2"]}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item>
                  <Space>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Create
                    </Button>
                    <Button onClick={() => navigate('/explore')}>
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CreateExplore; 