import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Table, Space, Select, DatePicker, Row, Col } from 'antd';
import { 
  getAllUserAttributes, 
  getUserAttributeAnalytics,
  getUserAcquisitionRevenue 
} from '../../graphql/queries';
import { 
  DollarOutlined, 
  UserOutlined, 
  ShoppingOutlined,
  BankOutlined,
  RiseOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import './UserAttributes.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

const UserAttributes = () => {
  const [filter, setFilter] = useState({
    store: undefined,
    keyword: undefined,
    startDate: undefined,
    endDate: undefined
  });

  const [pagination, setPagination] = useState({
    skip: 0,
    take: 10
  });

  // Query for revenue data
  const { data: revenueData, loading: revenueLoading } = useQuery(getUserAcquisitionRevenue, {
    variables: {
      filter
    }
  });

  // Query for analytics data
  const { data: analyticsData, loading: analyticsLoading } = useQuery(getUserAttributeAnalytics, {
    variables: {
      filter
    }
  });

  // Query for table data
  const { data: tableData, loading: tableLoading } = useQuery(getAllUserAttributes, {
    variables: {
      pagination,
      filter
    }
  });

  const revenueStats = [
    {
      title: 'Total Revenue',
      value: Number(revenueData?.getUserAcquisitionRevenue?.total || 0).toFixed(2),
      prefix: <DollarOutlined />,
      color: '#1890ff'
    },
    {
      title: 'After Tax',
      value: Number(revenueData?.getUserAcquisitionRevenue?.totalAfterTax || 0).toFixed(2),
      prefix: <BankOutlined />,
      color: '#52c41a'
    },
    {
      title: 'After Commission',
      value: Number(revenueData?.getUserAcquisitionRevenue?.totalAfterCommission || 0).toFixed(2),
      prefix: <ShoppingOutlined />,
      color: '#722ed1'
    },
    {
      title: 'Net Revenue',
      value: Number(revenueData?.getUserAcquisitionRevenue?.totalNet || 0).toFixed(2),
      prefix: <RiseOutlined />,
      color: '#f5222d'
    }
  ];

  const userStats = [
    {
      title: 'Organic Users',
      value: revenueData?.getUserAcquisitionRevenue?.organicUserCount || 0,
      subTitle: `$${Number(revenueData?.getUserAcquisitionRevenue?.organic || 0).toFixed(2)}`,
      color: '#52c41a'
    },
    {
      title: 'Non-Organic Users',
      value: revenueData?.getUserAcquisitionRevenue?.nonOrganicUserCount || 0,
      subTitle: `$${Number(revenueData?.getUserAcquisitionRevenue?.nonOrganic || 0).toFixed(2)}`,
      color: '#1890ff'
    },
    {
      title: 'Unknown Users',
      value: revenueData?.getUserAcquisitionRevenue?.unknownUserCount || 0,
      subTitle: `$${Number(revenueData?.getUserAcquisitionRevenue?.unknown || 0).toFixed(2)}`,
      color: '#faad14'
    },
    {
      title: 'Total Users',
      value: revenueData?.getUserAcquisitionRevenue?.totalUserCount || 0,
      subTitle: `$${Number(revenueData?.getUserAcquisitionRevenue?.total || 0).toFixed(2)}`,
      color: '#722ed1'
    }
  ];

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      width: 120,
    },
    {
      title: 'Media Source',
      dataIndex: 'mediaSource',
      key: 'mediaSource',
      width: 120,
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      width: 200,
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      width: 180,
    },
    {
      title: 'AF Status',
      dataIndex: 'afStatus',
      key: 'afStatus',
      width: 180,
    },
    {
      title: 'AF Channel',
      dataIndex: 'afChannel',
      key: 'afChannel',
      width: 180,
    },
    {
      title: 'AF Ad Type',
      dataIndex: 'afAdType',
      key: 'afAdType',
      width: 100,
    },
    {
      title: 'Original Cost',
      dataIndex: 'origCost',
      key: 'origCost',
      width: 100,
    },
    {
      title: 'Install Time',
      dataIndex: 'installTime',
      key: 'installTime',
      width: 160,
      render: (date) => date ? new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) : '-'
    },
    {
      title: 'Click Time',
      dataIndex: 'clickTime',
      key: 'clickTime',
      width: 160,
      render: (date) => date ? new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }) : '-'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 160,
      render: (date) => new Date(date).toLocaleString('tr-TR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  ];

  const handleStoreChange = (value) => {
    setFilter(prev => ({ ...prev, store: value }));
    setPagination(prev => ({ ...prev, skip: 0 }));
  };

  const handleKeywordChange = (value) => {
    setFilter(prev => ({ ...prev, keyword: value }));
    setPagination(prev => ({ ...prev, skip: 0 }));
  };

  const handleDateRangeChange = (dates) => {
    if (!dates) {
      setFilter(prev => ({ ...prev, startDate: undefined, endDate: undefined }));
    } else {
      setFilter(prev => ({
        ...prev,
        startDate: dates[0].startOf('day').toISOString(),
        endDate: dates[1].endOf('day').toISOString()
      }));
    }
    setPagination(prev => ({ ...prev, skip: 0 }));
  };

  const handleTableChange = (newPagination) => {
    setPagination({
      skip: (newPagination.current - 1) * newPagination.pageSize,
      take: newPagination.pageSize
    });
  };

  return (
    <div className="user-attributes-container">
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        {/* Filters Section */}
        <Card size="small" className="filters-section">
          <Row gutter={[16, 16]} align="middle">
            <Col xs={24} sm={8}>
              <Select
                placeholder="Select Store"
                style={{ width: '100%' }}
                onChange={handleStoreChange}
                allowClear
              >
                <Option value="PLAY_STORE">Play Store</Option>
                <Option value="APP_STORE">App Store</Option>
              </Select>
            </Col>
            <Col xs={24} sm={8}>
              <Select
                placeholder="Select Keyword"
                style={{ width: '100%' }}
                onChange={handleKeywordChange}
                allowClear
              >
                <Option value="Organic">Organic</Option>
                <Option value="Non-organic">Non-organic</Option>
              </Select>
            </Col>
            <Col xs={24} sm={8}>
              <RangePicker 
                onChange={handleDateRangeChange}
                showTime
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
        </Card>

        {/* Stats Overview */}
        <Row gutter={[16, 16]}>
          {/* Revenue Stats */}
          {revenueStats.map((stat, index) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <Card size="small" className="stat-card" bordered={false}>
                <div className="stat-header">
                  <span className="stat-icon" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
                    {stat.prefix}
                  </span>
                  <span className="stat-title">{stat.title}</span>
                </div>
                <div className="stat-value" style={{ color: stat.color }}>${stat.value}</div>
              </Card>
            </Col>
          ))}
        </Row>

        <Row gutter={[16, 16]}>
          {/* User Stats */}
          {userStats.map((stat, index) => (
            <Col xs={24} sm={12} md={6} key={index}>
              <Card size="small" className="stat-card" bordered={false}>
                <div className="stat-header">
                  <span className="stat-icon" style={{ backgroundColor: `${stat.color}15`, color: stat.color }}>
                    <UserOutlined />
                  </span>
                  <span className="stat-title">{stat.title}</span>
                </div>
                <div className="stat-value" style={{ color: stat.color }}>{stat.value}</div>
                <div className="stat-subtitle">{stat.subTitle}</div>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Table Section */}
        <Card bodyStyle={{ padding: '12px' }}>
          <Table
            columns={columns}
            dataSource={tableData?.getAllUserAttributes?.items}
            loading={tableLoading}
            rowKey="id"
            scroll={{ x: 'max-content' }}
            size="small"
            pagination={{
              current: Math.floor(pagination.skip / pagination.take) + 1,
              pageSize: pagination.take,
              total: tableData?.getAllUserAttributes?.total || 0,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} records`,
              size: "small"
            }}
            onChange={handleTableChange}
          />
        </Card>
      </Space>
    </div>
  );
};

export default UserAttributes; 