import React, { useState, useEffect } from 'react';
import { Table, Input, Space, Card, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllUsers } from '../../graphql/queries';
import './Users.css';

const { Search } = Input;

const Users = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(searchParams.get('search') || '');
  const [pagination, setPagination] = useState({
    current: parseInt(searchParams.get('page')) || 1,
    pageSize: parseInt(searchParams.get('pageSize')) || 10,
  });

  const { loading, data, refetch } = useQuery(getAllUsers, {
    variables: {
      page: pagination.current,
      pageSize: pagination.pageSize,
      rcUserId: searchText || null,
    },
  });

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchText) params.set('search', searchText);
    params.set('page', pagination.current.toString());
    params.set('pageSize', pagination.pageSize.toString());
    setSearchParams(params);
  }, [pagination, searchText, setSearchParams]);

  const columns = [
    {
      title: 'RC User ID',
      dataIndex: 'rcUserId',
      key: 'rcUserId',
      filterable: true,
      render: (text) => text || 'N/A',
    },
    {
      title: 'Premium Status',
      dataIndex: 'isPremium',
      key: 'isPremium',
      filters: [
        { text: 'Premium', value: true },
        { text: 'Regular', value: false },
      ],
      render: (isPremium) => (
        <Tag color={isPremium ? 'gold' : 'default'}>
          {isPremium ? 'Premium' : 'Regular'}
        </Tag>
      ),
    },
    {
      title: 'User Type',
      dataIndex: 'isOrganic',
      key: 'isOrganic',
      render: (isOrganic) => (
        <Tag color={isOrganic === null ? 'default' : isOrganic ? 'green' : 'blue'}>
          {isOrganic === null ? 'Unknown' : isOrganic ? 'Organic' : 'Non-Organic'}
        </Tag>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => date ? new Date(date).toLocaleDateString() : 'N/A',
      sorter: (a, b) => new Date(a.createdAt || 0) - new Date(b.createdAt || 0),
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination(newPagination);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setPagination({ ...pagination, current: 1 });
    refetch({
      page: 1,
      pageSize: pagination.pageSize,
      rcUserId: value || null,
    });
  };

  const handleRowClick = (record) => {
    navigate(`/user/${record.id}`);
  };

  return (
    <div className="users-container">
      <Card>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Search
            placeholder="Search by RC User ID"
            allowClear
            enterButton={<SearchOutlined />}
            size="large"
            onSearch={handleSearch}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Table
            columns={columns}
            dataSource={data?.getAllUsers?.users}
            loading={loading}
            rowKey="id"
            pagination={{
              ...pagination,
              total: data?.getAllUsers?.totalCount,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} users`,
            }}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
              style: { cursor: 'pointer' }
            })}
          />
        </Space>
      </Card>
    </div>
  );
};

export default Users; 