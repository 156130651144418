import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { 
  Card, 
  Form, 
  Input, 
  Select, 
  Button, 
  Space, 
  message, 
  Row, 
  Col, 
  Alert, 
  Typography,
  Tabs 
} from 'antd';
import { getSystem } from '../../graphql/queries';
import { updateSystem } from '../../graphql/mutation';
import './System.css';

const { Option } = Select;
const { Title } = Typography;

const SystemPage = () => {
  const [activeTab, setActiveTab] = useState('img2Video');
  const [systemData, setSystemData] = useState({
    img2Video: {
      id: '',
      system: '',
      provider: '',
      type: '',
      createdAt: '',
      updatedAt: '',
    },
    text2Video: {
      id: '',
      system: '',
      provider: '',
      type: '',
      createdAt: '',
      updatedAt: '',
    },
    toolPredict: {
      id: '',
      system: '',
      provider: '',
      type: '',
      createdAt: '',
      updatedAt: '',
    }
  });

  const [updateSystemMutation] = useMutation(updateSystem);

  const { data: data1 } = useQuery(getSystem, {
    variables: { systemType: 'img2Video' },
  });

  const { data: data2 } = useQuery(getSystem, {
    variables: { systemType: 'text2Video' },
  });

  const { data: data3 } = useQuery(getSystem, {
    variables: { systemType: 'toolPredict' },
  });

  useEffect(() => {
    if (data1?.getSystem) {
      setSystemData(prev => ({ ...prev, img2Video: data1.getSystem }));
    }
    if (data2?.getSystem) {
      setSystemData(prev => ({ ...prev, text2Video: data2.getSystem }));
    }
    if (data3?.getSystem) {
      setSystemData(prev => ({ ...prev, toolPredict: data3.getSystem }));
    }
  }, [data1, data2, data3]);

  const handleChange = (name, value) => {
    setSystemData(prev => ({
      ...prev,
      [activeTab]: {
        ...prev[activeTab],
        [name]: value
      }
    }));
  };

  const handleUpdate = async () => {
    try {
      const currentSystem = systemData[activeTab];
      await updateSystemMutation({
        variables: {
          updateSystemId: currentSystem.id,
          system: currentSystem.system,
          provider: currentSystem.provider,
          systemType: currentSystem.type,
        },
      });
      message.success(`${activeTab} system has been successfully updated.`);
    } catch (error) {
      message.error('Update failed: ' + error.message);
    }
  };

  const renderSystemForm = () => {
    const currentSystem = systemData[activeTab];
    const isImg2Video = activeTab === 'img2Video';

    return (
      <Form layout="vertical">
        <Form.Item label="Type">
          <Input value={currentSystem.type} disabled />
        </Form.Item>

        <Form.Item label="System">
          <Select
            value={currentSystem.system}
            onChange={(value) => handleChange('system', value)}
            disabled={isImg2Video}
          >
            <Option value="PAID">PAID</Option>
            <Option value="UNLIMITED">UNLIMITED</Option>
            <Option value="BOTH">BOTH</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Provider">
          <Select
            value={currentSystem.provider}
            onChange={(value) => handleChange('provider', value)}
          >
            <Option value="RUNWAY">RUNWAY</Option>
            <Option value="SORA">SORA</Option>
            <Option value="HAILUOAI">HAILUOAI</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Created At">
          <Input value={currentSystem.createdAt} disabled />
        </Form.Item>

        <Form.Item label="Updated At">
          <Input value={currentSystem.updatedAt} disabled />
        </Form.Item>

        <Button type="primary" onClick={handleUpdate}>
          Update System
        </Button>
      </Form>
    );
  };

  const items = [
    {
      key: 'img2Video',
      label: `img2Video System (${systemData.img2Video.provider})`,
      children: renderSystemForm(),
    },
    {
      key: 'text2Video',
      label: `text2Video System (${systemData.text2Video.provider})`,
      children: renderSystemForm(),
    },
    {
      key: 'toolPredict',
      label: `toolPredict System (${systemData.toolPredict.provider})`,
      children: renderSystemForm(),
    },
  ];

  return (
    <div className="system-container">
      <Card>
        <Tabs
          activeKey={activeTab}
          onChange={setActiveTab}
          items={items}
          className="system-tabs"
        />
      </Card>

      <Row gutter={[0, 16]} className="system-alerts">
        <Col span={24}>
          <Alert
            message="text2Video BOTH system value determines whether to use unlimited or paid account based on the user's video count on the backend."
            type="info"
            showIcon
          />
        </Col>
        <Col span={24}>
          <Alert
            message="img2Video will always work with paid account, so it cannot be changed."
            type="warning"
            showIcon
          />
        </Col>
        <Col span={24}>
          <Alert
            message="toolPredict system is used for predicting which tool to use for video generation."
            type="info"
            showIcon
          />
        </Col>
      </Row>
    </div>
  );
};

export default SystemPage; 